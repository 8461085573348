<template>
    <!-- 组件的 HTML 结构 -->
<el-container>
    <el-main>
    
        <el-col :xs="24" :sm="12" :md="12" :lg="12">
            <div>
        <h1> YouthWill AR Demo</h1>

    <p>点击下面的 3D 模型，查看YouthWill杯子</p>
    
    <!-- 使用一个链接，指向 .usdz 文件 -->
    <!-- rel="ar" 是 Apple AR Quick Look 特有的，允许 iOS 用户直接在 AR 中查看模型 -->
    <a href="/assets/usdz/cup-01.usdz" rel="ar">
   <img src="/assets/usdz/cup-01-image.jpg" alt="3D model">
   </a>
    
    <!-- 可以添加更多的 .usdz 模型链接 -->
    <!-- 确保每个模型都有一个链接和缩略图 -->
    </div>
    </el-col>
    </el-main>
</el-container>
  </template>
  
  <script>
  export default {
    name: 'FileLibrary',
    // 在这里定义组件的数据、方法、生命周期钩子等
  }
  </script>
  
  <style>

.el-container {
  width: 100%;
  /* 默认没有外边距 */
}
  /* 组件的 CSS 样式 */

  /* 使图片宽度不超过其父元素的宽度 */
img {
  max-width: 100%;
  height: auto; /* 保持图片的纵横比 */
}
  </style>